<template>
	<div></div>
</template>

<script>
	export default{
		name: 'ScrollPagination'
		,props: ['callBack']
		,created: function(){
			this.onEvent()
		}
		,data: function(){
			return {
				bottom: false
			}
		}
		,methods: {
			onEvent: function(){
				window.addEventListener('scroll', () => {
					this.bottom = this.bottomVisible()
				})
			}
			,bottomVisible() {
				const scrollY = window.scrollY
				const visible = document.documentElement.clientHeight
				const pageHeight = document.documentElement.scrollHeight
				const bottomOfPage = visible + scrollY >= pageHeight
				return bottomOfPage || pageHeight < visible
			}
		}
		,watch: {
			bottom(bottom) {
				if (bottom) {
					this.$emit('onScroll')
				}
			}
		}
	}
</script>